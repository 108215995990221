import React, { useEffect, useRef } from "react"
import { Formik } from 'formik'
import AOS from 'aos';

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

import Icone from "../images/icon.png"

const Usar = ({ location }) => {

  const params = new URLSearchParams(location.search)
  const emailInput = useRef(null);

  const [values, setValue] = React.useState({})
  const [statusCadastro, setStatusCadastro] = React.useState(false)

  const txtEmail =  params.get("txtEmail")? params.get("txtEmail") : ''


  useEffect(() => {
     AOS.init()
     emailInput.current.focus()
  }, []);


  return (
    <main>
        <Seo
        title="Usar o wEstoque 👍🏻"
        description="Comece agora a usar o sistema de gestão comercial e controle de estoque e vendas wEstoque! Teste sem compromisso e sem custos adicionais. Pague apenas quando usar :) Acesse agora!"
		keywords={[
            `Gestão de estoque online`,
            `Sistema de controle de estoque integrado`,
            `Gerenciamento de inventário em tempo real`,
            `Controle de múltiplos estoques`,
			`testar grátis`,
			`usar gratuitamente`,
			`teste westoque`,
			`teste grátis`,
			`usar o westoque`,
			`sem limitações`,
			`sem limite usuários`,
		]}
        />

        <Header />

        <section style={{paddingTop: `70px`, marginBottom: `250px`}}>

            <div className="max-w-6xl mx-auto px-6 sm:px-4 overflow-x-hidden">
                <div className="py-8 md:py-15">        

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 aos-init aos-animate mt-0" data-aos="fade-left">

                        <div className="p-2 m-2 min-w-min order-last md:order-first md:pt-16">
                            <h1 className="text-left text-2xl md:text-3xl font-bold pt-7">Preencha os campos e use grátis por 7 dias.</h1>
                            <br/>
                            <h1 className="text-xl text-gray-700">💳&nbsp;&nbsp;<span className="underline">NÃO PRECISA</span> de Cartão de Crédito.<br/>
                            😃&nbsp;&nbsp;<span className="underline">TESTE</span> Sem Compromisso.</h1>
                            <br/>
                            <div className="text-left">
                                Já tem cadastro/usa o wEstoque? <a href="https://sistema.westoque.com.br" target="_blank" rel="noreferrer" className="text-westoque-500 hover:text-westoque-600 hover:underline" >Faça aqui o seu Login</a>
                            </div>
                            <br/>
                               
                        </div>
                        
                        {
                            !statusCadastro ? (
                        
                        <div className="p-2 m-2 min-w-min text-center md:pt-16" id="PainelDadosConta">
                            <h1 className="font-semibold text-xl text-purple-600 mb-2">Inclua os dados abaixo e comece usar agora:</h1>
                            
                            <Formik
                            initialValues={{ "emailCadastro": txtEmail, "nomeCadastro": '', "telefoneCadastro": '', "senhaCadastro": '' }}
                            validate={values => {
                                const errors = {};
                                if (!values.emailCadastro) {
                                    errors.emailCadastro = 'Campo Obrigatório';
                                } 
                                else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailCadastro)){
                                    errors.emailCadastro = 'Endereço de Email Inválido';
                                }

                                if (!values.senhaCadastro) {
                                    errors.senhaCadastro = 'Campo Obrigatório';
                                } 

                                return errors;
                            }}
                            onSubmit={ async (values) => {
                                setStatusCadastro(true)
                                values.tipoCadastro = 2;
                                const result = await fetch(`https://www.westoque.com.br/cadastroJS.php`, {
                                    method: `POST`,
                                    body: JSON.stringify(values),
                                    cache: 'no-cache',
                                    credentials: 'omit', 
                                });
                                const resultData = await result.json()
                                
                                if (resultData.status === 'success'){
                                    document.location.href = `/bem-vindo`
                                }else{
                                    setStatusCadastro(false)
                                    alert(resultData.message)
                                }
                            }}

                            handleChange={(e) => {
                                values.e.target.id = e.target.value
                                setValue({ ...values })
                            }}
                            

                            >
                            {({
                                values,
                                errors,
                                touched,
                                txtEmail,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            }) => (
                                <form onSubmit={handleSubmit} method="POST" name="frmCadastro" id="frmCadastro">
                                <input
                                    type="email"
                                    name="emailCadastro"
                                    id="emailCadastro"
                                    className="w-full pl-3 pr-10 py-2 rounded-xl border-0shadow-sm focus:bg-blue-100"
                                    placeholder="Digite Seu E-mail" 
                                    ref={emailInput}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    value={values.emailCadastro}

                                />
                                <br/>
                                <div className="text-xs text-red-500 text-left w-max p-1">&nbsp;{errors.emailCadastro && touched.emailCadastro && errors.emailCadastro}</div>
                                <input 
                                    type="text" 
                                    name="nomeCadastro" 
                                    id="nomeCadastro" 
                                    className="w-full pl-3 pr-10 py-2 rounded-xl border-0 shadow-sm focus:bg-blue-100" 
                                    placeholder="Digite Seu Nome" 
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                    value={values.nomeCadastro}
                                    autoComplete="off"
                                />
                                <br/>
                                <div className="text-xs text-red-500 text-left w-max p-1">&nbsp;</div>
                                <input 
                                    type="text" 
                                    id="telefoneCadastro" 
                                    name="telefoneCadastro" 
                                    className="w-full pl-3 pr-10 py-2 rounded-xl border-0 shadow-sm focus:bg-blue-100" 
                                    placeholder="Digite Seu Telefone" 
                                    onChange={handleChange} 
                                    value={values.telefoneCadastro}
                                    autoComplete="off"
                                />
                                <br/>
                                <div className="text-xs text-red-500 text-left w-max p-1">&nbsp;</div>
                                <input 
                                    type="password" 
                                    id="senhaCadastro" 
                                    name="senhaCadastro" 
                                    className="w-full pl-3 pr-10 py-2 rounded-xl border-0 shadow-sm focus:bg-blue-100" 
                                    placeholder="Digite/Crie uma Senha de Acesso" 
                                    onChange={handleChange} 
                                    value={values.senhaCadastro}
                                    autoComplete="off"
                                />
                                <br/>
                                <div className="text-xs text-red-500 text-left w-max p-1">&nbsp;{errors.senhaCadastro && touched.senhaCadastro && errors.senhaCadastro}</div>
                                <button 
                                    type="submit" 
                                    id="btnEnvia" 
                                    name="btnEnvia" 
                                    className="text-xl mx-auto cursor-pointer inline-flex items-center justify-center border border-transparent px-3 py-2 my-2 rounded-md text-white bg-westoque-400 hover:transition-transform ease-in-out duration-1000 hover:scale-110 hover:underline">
                                        CRIAR CONTA
                                </button>
                                <br/>
                                <h1 className="text-xs pt-2">Criando a conta você concorda com os <a href="/termos-de-uso" className="text-westoque-500 hover:text-westoque-600 hover:underline">Termos de Uso do wEstoque</a></h1>
                                </form>
                            )}
                            </Formik>
                            <br/>
                            <h1 className="text-xs pt-2">Já tem cadastro? Ir para o <a href="https://sistema.westoque.com.br" target="_blank" rel="noreferrer" className="text-westoque-500 hover:text-westoque-600 hover:underline">Login</a></h1>
                        </div>

                        ) : (
                        <div className="p-2 m-2 min-w-min text-center" id="PainelCriandoConta">
                            <h1 className="font-semibold text-3xl text-purple-600 mb-2 pt-10">Aguarde um instante... <br/>Sua conta está sendo criada 😃</h1>
                            
                            <br/><br/>
                            <img src={Icone} alt="Sistema wEstoque - PDV online, financeiro e fiscal" className="mx-auto h-auto animate-bounce" width={100} />
                            
                            
                        </div>
                        )
                        }
                    </div>




                </div>
            </div>
        </section>


        <Footer />

    </main>
  )
}

export default Usar
